document.addEventListener('DOMContentLoaded', () => {
    /**
     * Using a timeout here because we need to wait for the global `bronson` variable.
     * You just have to import the script after the main `bronson.min.js` and can
     * safely omit the `setTimeout` used here.
     */
    const CONSENT_STORAGE_ID = 'show-plyr-video-preference-UNIQUE_ID';

    setTimeout(function () {
        document.querySelectorAll('.js-video').forEach(function (element) {
            const consentCallout = element.parentElement.querySelector('.js-video-consent');
            const consentButton = consentCallout.querySelector('.js-video-consent-trigger');
            const userPref = localStorage.getItem(CONSENT_STORAGE_ID);

            /**
             * This function initializes all video players on the page.
             * Iterates over all elements with the class "js-video" to
             * get their respective video component and call the `init` method.
             */
            const initAllVideos = () => {
                document.querySelectorAll('.js-video').forEach(el => {
                    const vidPlayer = bronson.$$(el).component('video').items[0];
                    el.parentElement.querySelector('.js-video-consent')?.setAttribute('hidden', '');
                    vidPlayer?.init();
                });

                /**
                 * Optional: Store a cookie or a localStorage to remember the user choice.
                 */
                localStorage.setItem(CONSENT_STORAGE_ID, "true");
            };

            /**
             * If the user already set the preference to show videos,
             * hide the callout and initiate the video players and cleanup
             * any EventListeners.
             */
            if (userPref === "true") {
                initAllVideos();
            } else {
                /**
                 * Attach an EventListener to the callout button.
                 */
                consentButton?.addEventListener('click', () => initAllVideos());
                consentCallout?.removeAttribute('hidden');
            }
        });
    }, 1000); /* Remove the setTimeout. */
});